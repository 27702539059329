<template>
  <div class="animated fadeIn">
    <b-card
      no-body
    >
      <b-card-header v-b-toggle.collapseContractorsCard>
        Редактирование списка наблюдаемых перевозчиков
      </b-card-header>
      <b-collapse
        id="collapseContractorsCard"
        visible
      >
        <b-card-body>
          <b-form
            novalidate
            @submit.prevent="onSubmit"
          >
            <b-form-group
              class="form-group-el-select"
              label="Перевозчик"
            >
              <b-form-row>
                <b-col>
                  <el-select
                    v-model="form.contractor.value"
                    class="form-control"
                    name="contractor"
                    placeholder="Начните вводить название компании перевозчика"
                    :filterable="true"
                    :remote="true"
                    reserve-keyword
                    :remote-method="contractorRemote"
                    :loading="loading"
                    @change="onContractorChange(form.contractor.value)"
                  >
                    <el-option
                      v-for="item in form.contractor.options"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                    />
                  </el-select>
                </b-col>
                <b-col cols="auto">
                  <b-button
                    type="button"
                    variant="secondary"
                    class="mr-2"
                    :class="{'loading' : loading}"
                    :disabled="loading"
                    @click="addAllContractors()"
                  >
                    Добавить всех
                  </b-button>
                </b-col>
              </b-form-row>
            </b-form-group>

            <v-client-table
              v-if="contractors.items.length > 0"
              id="dataTable"
              ref="table"
              :data="contractors.items"
              :columns="contractors.columns"
              :options="contractors.options"
              :theme="theme"
            >
              <span
                slot="phone"
                slot-scope="props"
              >
                <a
                  :href="`tel:${props.row.phone}`"
                  class="icon-email"
                >{{ props.row.phone }}</a>
              </span>
              <span
                slot="driversCount"
                slot-scope="props"
              >
                <span v-if="!props.row.driversCount">0</span>
                <span v-else>{{ props.row.driversCount }}</span>
              </span>
              <span
                slot="trucksCount"
                slot-scope="props"
              >
                <span v-if="!props.row.trucksCount">0</span>
                <span v-else>{{ props.row.trucksCount }}</span>
              </span>
              <span
                slot="trailersCount"
                slot-scope="props"
              >
                <span v-if="!props.row.trailersCount">0</span>
                <span v-else>{{ props.row.trailersCount }}</span>
              </span>
              <span
                slot="notificationEmail"
                slot-scope="props"
              >
                <a
                  v-if="props.row.notificationEmail"
                  :href="`mailto:${props.row.notificationEmail}`"
                  class="icon-email"
                >{{ props.row.notificationEmail }}</a>
                <a
                  v-else
                  :href="`mailto:${props.row.email}`"
                  class="icon-email"
                >{{ props.row.email }}</a>
              </span>
              <span
                slot="actions"
                slot-scope="props"
              >
                <a
                  v-if="$store.getters.isAdmin"
                  v-b-tooltip.hover.bottom="'Удалить'"
                  class="fa fa-close fa-lg text-danger table-action-button"
                  @click="deleteChosenContractor(props.row.id)"
                />
              </span>
            </v-client-table>

            <b-button
              type="submit"
              variant="primary"
              :disabled="loading"
              :class="{'loading' : loading}"
            >
              Сохранить
            </b-button>
          </b-form>
        </b-card-body>
        <b-collapse />
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue';

import {ClientTable} from 'vue-tables-2';
import notifications from '../../../components/mixins/notifications';
import {required} from 'vuelidate/lib/validators';
import {
  customersContractorsList,
  customersContractorRead,
  customersUserReadWithContractors,
  customerUserContractorsUpdate,
  customersAllContractorsList,
} from '../../../services/api';

Vue.use(ClientTable);

export default {
  name: 'CustomerListenedContractors',
  components: {
  },
  mixins: [notifications],
  data: function() {
    return {
      previousData: [],
      form: {
        contractor: {
          options: [],
          value: null,
        },
      },
      contractors: {
        columns: [
          'name',
          'phone',
          'driversCount',
          'trucksCount',
          'trailersCount',
          'notificationEmail',
          'INN',
          'actions',
        ],
        options: {
          headings: {
            'name': 'Название',
            'phone': 'Телефон',
            'driversCount': 'Кол-во водителей',
            'trucksCount': 'Кол-во грузовиков',
            'trailersCount': 'Кол-во прицепов',
            'notificationEmail': 'Email',
            'INN': 'ИНН',
            'actions': '',
          },
          sortable: ['name'],
          filterable: ['name'],
          sortIcon: {
            base: 'fa',
            up: 'fa-sort-asc',
            down: 'fa-sort-desc',
            is: 'fa-sort',
          },
          orderBy: {
            column: 'name',
          },
          pagination: {
            chunk: 10,
            edge: true,
            nav: 'fixed',
          },
          texts: {
            count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
            first: 'Первая',
            last: 'Последняя',
            filter: 'Фильтр:',
            filterPlaceholder: 'Поисковый запрос',
            limit: 'Записи:',
            page: 'Страница:',
            noResults: 'Нет совпадающих записей',
            filterBy: 'Фильтр по {column}',
            loading: 'Загрузка...',
            defaultOption: 'Выбор {column}',
            columns: 'Столбцы',
          },
        },
        items: [],
        fullDistance: '',
        theme: 'bootstrap4',
      },
      loading: false,
      useVuex: false,
      theme: 'bootstrap4',
      template: 'default',
    };
  },
  computed: {
    formStr() {
      const add = []; const remove = [];
      this.contractors.items.forEach((contractor) =>{
        let notUsed = true;
        for (const previousContractor of this.previousData) {
          if (previousContractor.id === contractor.id) {
            notUsed = false;
            break;
          }
        }
        if (notUsed) {
          add.push(contractor.id);
        }
      });
      this.previousData.forEach((previousContractor) =>{
        let notUsed = true;
        for (const contractor of this.contractors.items) {
          if (previousContractor.id === contractor.id) {
            notUsed = false;
            break;
          }
        }
        if (notUsed) {
          remove.push(previousContractor.id);
        }
      });
      return {add, remove};
    },
    isValid() {
      return !this.$v.form.$anyError;
    },
  },
  validations: {
    form: {
      name: {
        required,
      },
      contractor: {
        value: '',
      },
    },
  },
  mounted() {
    this.customersUserReadWithContractors();
    this.contractorRemote('');
  },
  methods: {
    async customersUserReadWithContractors() {
      this.loading = true;
      const response = await customersUserReadWithContractors(
        this.$store.state.user.ownerId,
        this.$store.state.user.userId,
      );
      if (response && response.status === 200) {
        this.form.name = response.data.name;
        this.contractors.items = response.data.contractors;
        this.previousData = [...response.data.contractors];
      }
      this.loading = false;
    },
    async addAllContractors() {
      this.loading = true;
      const response = await customersAllContractorsList(this.$store.state.user.ownerId, {valid: true});
      if (response && response.status === 200) {
        this.contractors.items = response.data.items.map((customerContractor)=> {
          return customerContractor.contractor;
        });
        this.form.contractor.options = [];
      }
      this.loading = false;
    },
    async contractorRemote(query) {
      this.loading = true;
      const response = await customersContractorsList(this.$store.state.user.ownerId, {
        name: query,
        limit: 100,
      });
      if (response && response.status === 200) {
        this.contractors.items.forEach((item) => {
          response.data.items = response.data.items.filter((x) => x.contractor.id !== item.id);
        });
        this.form.contractor.options = response.data.items.map((item) => {
          return {
            value: item.contractor.id,
            text: item.contractor.name,
          };
        });
      }
      this.loading = false;
    },
    async onContractorChange(contractorId) {
      this.loading = true;
      const response = await customersContractorRead(this.$store.state.user.ownerId, contractorId);
      if (response && response.status === 200) {
        this.form.contractor.options = this.form.contractor.options.filter((item) => item.value !== contractorId);
        this.form.contractor.value = null;
        this.contractors.items.unshift(response.data.contractor);
      }
      this.loading = false;
    },
    deleteChosenContractor(contractorId) {
      this.contractors.items = this.contractors.items.filter((item) => item.id !== contractorId);
    },
    async onSubmit() {
      this.loading = true;
      const response = await customerUserContractorsUpdate(this.$store.state.user.userId, this.formStr);
      if (response && response.status === 200) {
        this.showSuccess('Список обновлен');
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
</style>
